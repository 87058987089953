import {
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography,
    useTheme,
    Box,
} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { navigate } from '@reach/router';
import React from 'react';
import Card from '../components/Controls/Card';
import ContinueButton from '../components/Controls/ContinueButton';
import CookieBar from '../components/Controls/CookieBar';
import SectionTitle from '../components/Controls/SectionTitle';
import Footer from '../components/Footer';
import HomepageHeader from '../components/HomepageHeader';

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        offset: theme.mixins.toolbar,
        heroSection: {
            paddingTop: '56px',
            position: 'relative',
            backgroundColor: theme.palette.grey[800],
            color: theme.palette.common.white,
            marginBottom: theme.spacing(2),
            backgroundImage: `url(${theme.metadata.homePageHero.heroImageUrl})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        },
        hero: {
            height: '500px',
            position: 'relative',
            '& h1': {
                color: `${theme.metadata.homePageHero.textColor} !important`,
            },
            '& > div': {
                position: 'absolute',
                top: '25%',
                transform: 'translate(0, -25%)',
                textAlign: 'center',
            },
        },
        heroSubheader: {
            lineHeight: 1.5,
            color: `${theme.metadata.homePageHero.textColor} !important`,
            fontSize: `${theme.metadata.homePageHero.subheadingFontSize}rem !important`,
        },
        infoBox: {
            display: 'flex',
        },
        panelOfInsurersText: {
            fontFamily: 'Spartan MB Bold',
            fontSize: '1.25rem',
            lineHeight: '1.875rem',
        },
        infoText: {
            fontSize: '0.875rem',
        },
        documentLink: {
            display: 'block',
            fontSize: '0.875rem',
            paddingTop: theme.spacing(1),
            color: theme.palette.text.primary,
        },
    });
});

const Index = () => {
    const classes = useStyles();
    const theme = useTheme();
    const title =
        theme.metadata.homePageHero.titleText || "Let's get your cheapest home insurance quote.";
    const subHeading =
        theme.metadata.homePageHero.subheadingText ||
        "We've teamed up with Uinsure, an award winning insurance provider to give our customers a comprehensive home insurance policy at a great price.";
    const insurerLogos = theme.siteConfiguration.insurerLogoGroup.categorySet;

    return (
        <>
            <HomepageHeader />
            <Container maxWidth={false} className={classes.heroSection}>
                <Container maxWidth='sm'>
                    <Grid item xs={12}>
                        <div className={classes.hero}>
                            <div>
                                <SectionTitle
                                    title={title}
                                    subHeading={subHeading}
                                    subHeadingClass={classes.heroSubheader}
                                />
                                <ContinueButton onClick={() => navigate('/direct')}>
                                    Get Quote
                                </ContinueButton>
                            </div>
                        </div>
                    </Grid>
                </Container>
            </Container>
            <Container maxWidth={false}>
                <Container maxWidth='md'>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Card title='' reducedMargin={true}>
                                <Grid container justify='center' spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography
                                            align='center'
                                            className={classes.panelOfInsurersText}
                                        >
                                            Uinsure Home Insurance underwritten by a panel of
                                            insurers.
                                        </Typography>
                                    </Grid>
                                    {insurerLogos.map((insurer, index) => (
                                        <Grid item xs={6} sm={4} md={2} key={index}>
                                            <Box textAlign='center'>
                                                <img
                                                    key={index}
                                                    src={`/img/avatars/${insurer}.png`}
                                                    height='60px'
                                                />
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4} className={classes.infoBox}>
                            <Card title='Policy Documents'>
                                <>
                                    <Typography className={classes.infoText}>
                                        Before you buy your home insurance policy, please read the
                                        policy documents below to ensure that the policy you select
                                        meets your needs. The policy wording and insurance product
                                        information document outline the benefits, limitations and
                                        exclusions that will apply to your cover.
                                    </Typography>
                                    <a
                                        id='policyWording'
                                        target='_blank'
                                        href={
                                            theme.metadata.contentManagedLinks.footerLinks
                                                .policyWordingUrl
                                        }
                                        className={classes.documentLink}
                                    >
                                        Policy Wording
                                    </a>
                                    <a
                                        id='insurancePID'
                                        target='_blank'
                                        href={
                                            theme.metadata.contentManagedLinks.footerLinks
                                                .insuranceProductInformationDocumentUrl
                                        }
                                        className={classes.documentLink}
                                    >
                                        Insurance Product Information Document
                                    </a>
                                </>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4} className={classes.infoBox}>
                            <Card title='About Uinsure'>
                                <>
                                    <Typography className={classes.infoText}>
                                        Your 5 Star insurance quotation will be provided by Uinsure,
                                        an award winning general insurance provider. For details of
                                        who Uinsure are, the services they provide and your
                                        cancellation rights, please read the about our insurance
                                        services document.
                                    </Typography>
                                    <a
                                        id='insuranceServices'
                                        target='_blank'
                                        href={
                                            theme.metadata.contentManagedLinks.footerLinks
                                                .aboutOurInsuranceServicesUrl
                                        }
                                        className={classes.documentLink}
                                    >
                                        About our Insurance Services
                                    </a>
                                    <br />
                                    <Box textAlign='center'>
                                        <img src='/img/defaqto.png' height='70px' />
                                    </Box>
                                </>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4} className={classes.infoBox}>
                            <Card title='Your information'>
                                <Typography className={classes.infoText}>
                                    Please make sure the information you enter during the quote
                                    process is accurate, as it could affect the cover you have under
                                    the policy or any claims you make. If you do not answer any
                                    questions honestly, withhold information, or do not select the
                                    correct level of cover to replace 'as new' your contents and
                                    valuables, personal possessions, specified items and bicycles,
                                    we may refuse to pay your claim, pay only part of your claim,
                                    and/or void your policy.
                                </Typography>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Container>
            <Footer />
            <CookieBar />
        </>
    );
};

export default Index;
